import React from "react";

export default class AdComp extends React.Component
{
    componentDidMount(): void {
        /* AD_UNIT_00 */
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    }

    render(): React.ReactNode {
        return (
            <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-client="ca-pub-9393776520733331"
            data-ad-slot="7782869943"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        );
    }
}